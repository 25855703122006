import "./card_app.css";

import { Card, Link } from "@material-ui/core";
import React from "react";
import { Col } from "react-bootstrap";

const CardApp = ({ logo, app, desc, link }) => {
	return (
		<Col lg="3" md="3" sm="6" xs="12" className="mb-4">
			<Link href={link} target="_blank" className="link-item-app">
				<Card className="item-app text-center p-3">
					<div className="container-logo">
						<img src={logo} alt="alan finance" />
					</div>
					<h4 className="app-name">{app}</h4>
					<p className="app-desc">{desc}</p>
				</Card>
			</Link>
		</Col>
	);
};

export default CardApp;
