import {} from "../../constants/constants";

const initState = {
  kop: [],
};

const KopSurat = (state = initState, action) => {
  switch (action.type) {
    case 1:
      return {
        ...state,
        kop: [],
      };
    default:
      return state;
  }
};

export default KopSurat;
