import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export const isLogin = () => {
	return !!localStorage.getItem("token");
};

export const isSuper = () => {
	const role = localStorage.getItem("role");
	return role.includes(1);
};

const UserAdmin = ({ component: Component, ...rest }) => {
	return <Route {...rest} render={(props) => (isSuper() ? <Component {...props} /> : <Redirect to="/" />)} />;
};

const UserLogin = ({ component: Component, ...rest }) => {
	return <Route {...rest} render={(props) => (isLogin() ? <Component {...props} /> : <Redirect to="/login" />)} />;
};

const UserNotLogin = ({ component: Component, ...rest }) => {
	return <Route {...rest} render={(props) => (isLogin() ? <Redirect to="/" /> : <Component {...props} />)} />;
};

const PrivateRoute = {
	UserLogin,
	UserNotLogin,
	isLogin,
	UserAdmin,
};

export default PrivateRoute;
