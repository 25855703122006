import { connectRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "react-router-redux";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import promise from "redux-promise-middleware";
import thunk from "redux-thunk";

import auth from "./reducers/auth";
import managementUser from "./reducers/managementUser";
import KopSurat from "./reducers/KopSurat";

export const history = createBrowserHistory();

const rootPersistConfig = {
  key: "root",
  storage: storage,
};
const reducers = combineReducers({
  router: connectRouter(history),
  auth,
  managementUser,
  KopSurat,
});
const rootReducer = persistReducer(rootPersistConfig, reducers);
const middleware = applyMiddleware(promise, thunk, routerMiddleware(history));

const store = createStore(rootReducer, middleware);
export default store;
