import { CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';

import { login } from '../../redux/actions/auth';

class Login extends Component {
	constructor() {
		super();
		this.state = {
			submit: false,
			email: "",
			password: "",
			error: false,
			error_text: "",
		};
	}

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	handleSubmit = (e) => {
		e.preventDefault();
		this.setState(
			{
				submit: true,
			},
			this.sendSubmit
		);
	};

	sendSubmit = async () => {
		const { handleLogin } = this.props;
		const { email, password } = this.state;
		const params = {
			email: email,
			password: password,
		};
		handleLogin(params)
			.then((res) => {
				if (res.value.code === 2) {
					this.setState({
						submit: false,
						error: true,
						error_text: res.value.message,
					});
				} else {
					window.location.href = "/";
				}
			})
			.catch((err) => {
				console.log('error',err);
				this.setState({
					submit: false,
					error: true,
					error_text: "Gagal login, coba beberapa saat lagi",
				});
			});
	};

	render() {
		const { submit, email, password, error, error_text } = this.state;
		return (
			<div className="container-welcome overflow-hide">
				<div className="ilustration-background">
					<Container>
						<Row>
							<Col
								className="text-center text-white container-header mt-5"
								lg={{ span: 6, offset: 3 }}
								md={{ span: 6, offset: 3 }}
								sm={{ span: 4, offset: 3 }}
								xs="12"
							>
								<h2 className="m-0 logo-text">Alan Office</h2>
								<p className="m-0 tagline">
									Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae quaerat modi, quam architecto earum quos consequatur
									dolor corporis cumque itaque quis.
								</p>
							</Col>
						</Row>
						<div className="form-login p-4">
							<h3 className="text-left text-login mb-4">Masuk</h3>
							{error ? (
								<Alert severity="error" className="mb-4">
									{error_text}
								</Alert>
							) : null}
							<ValidatorForm onSubmit={this.handleSubmit}>
								<TextValidator
									className="mb-6 w-100 text-raleway mb-3"
									variant="outlined"
									label="Email"
									type="email"
									name="email"
									value={email}
									validators={["required", "isEmail"]}
									errorMessages={["Email harus diisi", "Email tidak valid"]}
									onChange={this.handleChange}
								/>
								<TextValidator
									className="mb-3 w-100 text-raleway"
									label="Password"
									variant="outlined"
									name="password"
									type="password"
									value={password}
									validators={["required"]}
									errorMessages={["Password harus diisi"]}
									onChange={this.handleChange}
								/>
								<button className="btn-signin mt-3" type="submit" disabled={submit}>
									{submit ? <CircularProgress size={15} color="primary" /> : "Masuk"}
								</button>
							</ValidatorForm>
						</div>
					</Container>
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		handleLogin: (params) => dispatch(login(params)),
	};
};

export default connect(null, mapDispatchToProps)(Login);
