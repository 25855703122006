import { Menu, MenuItem } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

import { AlanOffice } from "../../assets";
import { getDetailUser, logout } from "../../redux/actions/auth";

const MainHeader = ({ user, logout, getDetailUser, history }) => {
  const [OpenMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    getDetailUser();
  }, [getDetailUser]);

  const handleCloseMenu = () => {
    setOpenMenu(null);
  };

  const handleOpenMenu = (e) => {
    setOpenMenu(e.currentTarget);
  };

  const handleManagement = () => {
    history.push("/management-user");
  };

  const handleKop = () => {
    history.push("/kop-surat");
  };

  const handleLogout = () => {
    logout()
      .then((res) => {
        history.push({
          pathname: "/login",
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          history.push({
            pathname: "/login",
          });
        } else {
          Swal.fire({
            title: "Gagal",
            text: "gagal keluar",
            icon: "error",
            timer: 2000,
          });
        }
      });
  };

  return (
    <header className="text-white container-header d-flex align-items-center justify-content-between flex-row py-4">
      <Link to="/">
        <img src={AlanOffice} alt="Alan Office" className="logo" />
      </Link>
      <div className="akun d-flex align-items-center">
        <div className="nama">
          <p className="m-0">Hallo,</p>
          <h6 className="m-0">{user?.name}</h6>
        </div>
        <button onClick={handleOpenMenu} className="btn-toggle-menu">
          <img
            src={`https://ui-avatars.com/api/?name=${user?.name}&background=97CB72&color=ffffff`}
            alt="Deni Juli Setiawan"
          />
        </button>
        <Menu
          style={{ marginTop: 20 }}
          anchorEl={OpenMenu}
          keepMounted
          open={Boolean(OpenMenu)}
          onClose={handleCloseMenu}
        >
          {user?.role.includes(1) ? (
            <MenuItem onClick={handleManagement}>Management User</MenuItem>
          ) : null}
          {/* <MenuItem onClick={handleKop}>Kop Surat</MenuItem> */}
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    getDetailUser: () => dispatch(getDetailUser()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MainHeader);
