import { createBrowserHistory } from "history";
import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router";

import { Home, Login, ManagementUser, KopSurat } from "../pages";
import PrivateRoute from "../utils/PrivateRoute";

export const history = createBrowserHistory();
class Routes extends Component {
  render() {
    return (
      <Route>
        <Switch>
          <PrivateRoute.UserLogin
            history={history}
            exact
            path="/"
            component={Home}
          />
          <PrivateRoute.UserAdmin
            history={history}
            exact
            path="/management-user"
            component={ManagementUser}
          />
          <PrivateRoute.UserAdmin
            history={history}
            exact
            path="/kop-surat"
            component={KopSurat}
          />
          <PrivateRoute.UserNotLogin
            history={history}
            exact
            path="/login"
            component={Login}
          />
        </Switch>
      </Route>
    );
  }
}
export default withRouter(Routes);
