import { GET_DETAIL_USER, LOGIN, LOGOUT } from "../../constants/constants";

const initState = {
	user: null,
};

const auth = (state = initState, action) => {
	switch (action.type) {
		case LOGIN + "_PENDING":
			return {
				...state,
				user: null,
			};
		case LOGIN + "_FULFILLED":
			return {
				...state,
				user: action.payload,
			};
		case LOGIN + "_REJECTED":
			return {
				...state,
				user: null,
			};
		case LOGOUT + "_PENDING":
			return state;
		case LOGOUT + "_FULFILLED":
			return {
				user: null,
			};
		case LOGOUT + "_REJECTED":
			return state;
		case GET_DETAIL_USER + "_PENDING":
			return state;
		case GET_DETAIL_USER + "_FULFILLED":
			return {
				user: action.payload,
			};
		case GET_DETAIL_USER + "_REJECTED":
			return state;
		default:
			return state;
	}
};

export default auth;
