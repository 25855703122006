import { API, setAuthToken } from '../../config/api';
import { ADD_USER, DEL_USER, GET_LIST_USER, UPD_USER } from '../../constants/constants';

export const getListUser = (params) => {
	return {
		type: GET_LIST_USER,
		payload: async () => {
			const token = localStorage.getItem("token");
			setAuthToken(token);
			const res = await API.get("user/list", params).catch((err) => {
				return Promise.reject(err);
			});
			return res.data.data;
		},
	};
};

export const addUser = (params) => {
	return {
		type: ADD_USER,
		payload: async () => {
			const token = localStorage.getItem("token");
			setAuthToken(token);
			const res = await API.post("user/create", params).catch((err) => {
				return Promise.reject(err);
			});
			return res.data.data;
		},
	};
};

export const delUser = (id) => {
	return {
		type: DEL_USER,
		payload: async () => {
			const token = localStorage.getItem("token");
			setAuthToken(token);
			await API.post(`user/delete/${id}`).catch((err) => {
				return Promise.reject(err);
			});
			return id;
		},
	};
};

export const updUser = (params) => {
	return {
		type: UPD_USER,
		payload: async () => {
			const token = localStorage.getItem("token");
			setAuthToken(token);
			const res = await API.post(`user/edit`, params).catch((err) => {
				return Promise.reject(err);
			});
			return res.data;
		},
	};
};
