import {
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  Card,
} from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { Col, Container, Row } from "react-bootstrap";

import { ValidatorForm } from "react-material-ui-form-validator";
import { editHeader } from "../../redux/actions/KopSurat";
import { MainHeader, ButtonUploadThumbnail } from "../../component";

class KopSurat extends Component {
  constructor() {
    super();
    this.state = {
      submit: false,
      error: false,
      header_preview:
        "https://api.finance.alan.co.id/storage/kop/Kop-header.png",
      footer_preview:
        "https://api.finance.alan.co.id/storage/kop/Kop-footer.png",
    };
  }

  componentDidMount() {}

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = () => {
    this.setState(
      {
        submit: true,
      },
      this.sendSubmit
    );
  };

  sendSubmit = () => {
    const { header, footer } = this.state;
    const { editHeader } = this.props;
    const params = {
      head: header,
      foot: footer,
    };
    editHeader(params)
      .then((res) => {
        if (res.code === 0) {
          Swal.fire({
            title: "Berhasil",
            text: "Data berhasil disimpan",
            timer: 2000,
            icon: "success",
          }).then(() => {
            this.setState({
              submit: false,
              footer: "",
              footerFile: undefined,
              header: "",
              headerFile: undefined,
            });
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Gagal",
          text: "Data gagal disimpan",
          timer: 2000,
          icon: "error",
        }).then(() =>
          this.setState({
            submit: false,
          })
        );
      });
  };

  handleUploadHeader = (file, path) => {
    this.setState({
      header: file.result,
      headerFile: path,
      header_preview: path,
    });
  };

  handleDelFileHeader = () => {
    this.setState({
      header: "",
      headerFile: undefined,
      header_preview:
        "https://api.finance.alan.co.id/storage/kop/Kop-header.png",
    });
  };

  handleUploadFooter = (file, path) => {
    this.setState({
      footer: file.result,
      footerFile: path,
      footer_preview: path,
    });
  };

  handleDelFileFooter = () => {
    this.setState({
      footer: "",
      footerFile: undefined,
      footer_preview:
        "https://api.finance.alan.co.id/storage/kop/Kop-footer.png",
    });
  };

  render() {
    let { submit, header_preview, headerFile, footer_preview, footerFile } =
      this.state;
    return (
      <div className="container-welcome">
        <div className="ilustration-background">
          <Container>
            <MainHeader history={this.props.history} />
            <Row>
              <Col>
                <Card className="p-5">
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={6}>
                      <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
                        <h4 className="mb-5">Edit Header &amp; Footer</h4>
                        <Grid container spacing={2}>
                          <Grid item sm={12} xs={12} className="pb-0">
                            <p className="ml-3">Header</p>
                            <div
                              style={{ width: "100%", height: 100 }}
                              className="relative"
                            >
                              {headerFile && (
                                <img
                                  src={headerFile}
                                  width="100%"
                                  height="100%"
                                  style={{ objectFit: "cover" }}
                                  alt="headerFile"
                                />
                              )}
                              <ButtonUploadThumbnail
                                uploadFoto={this.handleUploadHeader}
                                value={headerFile}
                                handleDelFile={this.handleDelFileHeader}
                              />
                            </div>
                          </Grid>
                          <Grid item sm={12} xs={12} className="pb-0 pt-5">
                            <p className="ml-3">Footer</p>
                            <div
                              style={{ width: "100%", height: 100 }}
                              className="relative"
                            >
                              {footerFile && (
                                <img
                                  src={footerFile}
                                  width="100%"
                                  height="100%"
                                  style={{ objectFit: "cover" }}
                                  alt="footerFile"
                                />
                              )}
                              <ButtonUploadThumbnail
                                uploadFoto={this.handleUploadFooter}
                                value={footerFile}
                                handleDelFile={this.handleDelFileFooter}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <div className="w-full text-right">
                          <ButtonGroup className="mt-3">
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={submit}
                              type="submit"
                            >
                              {submit ? (
                                <CircularProgress size={15} color="#fff" />
                              ) : (
                                "Simpan"
                              )}
                            </Button>
                          </ButtonGroup>
                        </div>
                      </ValidatorForm>
                    </Grid>
                    <Grid item sm={6} xs={6}>
                      <h4 className="mb-5">Preview</h4>
                      <Grid container spacing={2}>
                        <Grid item sm={12} xs={12}>
                          <img
                            src={header_preview}
                            alt="header"
                            style={{ width: "100%" }}
                          />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <img
                            src="https://api.finance.alan.co.id/storage/kop/Kop-main.png"
                            alt="main"
                            style={{ width: "100%" }}
                          />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <img
                            src={footer_preview}
                            alt="footer"
                            style={{ width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    editHeader: (params) => dispatch(editHeader(params)),
  };
};

export default connect(null, mapDispatchToProps)(KopSurat);
