import {
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControlLabel,
} from "@material-ui/core";
import React, { Component } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import Swal from "sweetalert2";

import { addUser, getListUser } from "../../redux/actions/managementUser";

class AddUser extends Component {
  constructor() {
    super();
    this.state = {
      nama: "",
      email: "",
      password: "",
      submit: false,
      role_user: [],
    };
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const { role_user } = this.state;
    if (role_user.length <= 0) {
      Swal.fire({
        title: "Error",
        text: "User harus memiliki paling sedikit 1 role",
        icon: "error",
        confirmButtonText: "Iya",
      });
      return false;
    }
    this.setState(
      {
        submit: true,
      },
      this.sendSubmit
    );
  };
  sendSubmit = () => {
    const { addUser, handleClose, getListUser } = this.props;
    const { nama, email, password, role_user } = this.state;
    const params = {
      name: nama,
      email: email,
      password: password,
      role: role_user.toString(),
    };
    addUser(params)
      .then(() => {
        Swal.fire({
          title: "Berhasil",
          text: "Data berhasil disimpan",
          timer: 2000,
          icon: "success",
        }).then(() => {
          this.setState({
            nama: "",
            email: "",
            password: "",
            submit: false,
            role_user: [],
          });
          getListUser();
          handleClose();
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "Gagal",
          text: "Data gagal disimpan",
          timer: 2000,
          icon: "error",
        }).then(() => {
          this.setState({
            submit: false,
          });
        });
      });
  };
  handleChangeRole = (e) => {
    const { role_user } = this.state;
    if (e.target.checked) {
      let roleUser = role_user;
      roleUser = roleUser.concat(e.target.name);
      if (parseInt(e.target.name) === 1) {
        roleUser = roleUser.filter((item) => parseInt(item) !== 2);
      } else if (parseInt(e.target.name) === 2) {
        roleUser = roleUser.filter((item) => parseInt(item) !== 1);
      }
      this.setState({
        role_user: roleUser,
      });
    } else {
      let roleUser = role_user.filter(
        (item) => parseInt(item) !== parseInt(e.target.name)
      );
      this.setState({
        role_user: roleUser,
      });
    }
  };
  render() {
    const { open, handleClose } = this.props;
    const { nama, email, password, submit, role_user } = this.state;
    return (
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <div className="px-5 py-3">
          <h5 className="mb-4">Tambah User</h5>
          <ValidatorForm onSubmit={this.handleSubmit}>
            <TextValidator
              variant="outlined"
              className="mb-3 w-100"
              label="Nama Lengkap"
              onChange={this.handleChange}
              type="text"
              name="nama"
              value={nama || ""}
              validators={["required", "minStringLength: 3"]}
              errorMessages={[
                "Nama harus diisi",
                "minimal panjang karakter, 3 karakter",
              ]}
            />
            <TextValidator
              variant="outlined"
              className="mb-3 w-100"
              label="Email"
              onChange={this.handleChange}
              type="email"
              name="email"
              value={email || ""}
              validators={["required", "isEmail"]}
              errorMessages={["Nama harus diisi", "Email tidak valid"]}
            />
            <TextValidator
              variant="outlined"
              className="mb-3 w-100"
              label="Password"
              onChange={this.handleChange}
              type="password"
              name="password"
              value={password || ""}
              validators={["required", "minStringLength: 8"]}
              errorMessages={[
                "Nama harus diisi",
                "Minimal panjang password, 8 karakter",
              ]}
            />
            <label>Role User</label>
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={
                    role_user.filter((item) => parseInt(item) === 1).length > 0
                  }
                  onChange={this.handleChangeRole}
                  name="1"
                  disabled={
                    role_user.filter((item) => parseInt(item) === 2).length > 0
                  }
                />
              }
              label="Super User"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={
                    role_user.filter((item) => parseInt(item) === 2).length > 0
                  }
                  onChange={this.handleChangeRole}
                  name="2"
                  disabled={
                    role_user.filter((item) => parseInt(item) === 1).length > 0
                  }
                />
              }
              label="User Biasa"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={
                    role_user.filter((item) => parseInt(item) === 3).length > 0
                  }
                  onChange={this.handleChangeRole}
                  name="3"
                />
              }
              label="Finance"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={
                    role_user.filter((item) => parseInt(item) === 4).length > 0
                  }
                  onChange={this.handleChangeRole}
                  name="4"
                />
              }
              label="HR"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={
                    role_user.filter((item) => parseInt(item) === 5).length > 0
                  }
                  onChange={this.handleChangeRole}
                  name="5"
                />
              }
              label="Administrator / CRP"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={
                    role_user.filter((item) => parseInt(item) === 6).length > 0
                  }
                  onChange={this.handleChangeRole}
                  name="6"
                />
              }
              label="CRM User"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={
                    role_user.filter((item) => parseInt(item) === 99).length > 0
                  }
                  onChange={this.handleChangeRole}
                  name="99"
                />
              }
              label="CRM Admin"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={
                    role_user.filter((item) => parseInt(item) === 7).length > 0
                  }
                  onChange={this.handleChangeRole}
                  name="7"
                  disabled={
                    role_user.filter((item) => parseInt(item) === 8).length > 0
                  }
                />
              }
              label="HR Admin"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={
                    role_user.filter((item) => parseInt(item) === 8).length > 0
                  }
                  onChange={this.handleChangeRole}
                  name="8"
                  disabled={
                    role_user.filter((item) => parseInt(item) === 7).length > 0
                  }
                />
              }
              label="HR User"
            />
            <div className="d-flex justify-content-end">
              <ButtonGroup>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={submit}
                  type="submit"
                  className="bg-primary"
                >
                  {submit ? (
                    <CircularProgress color="#fff" size={15} />
                  ) : (
                    "Simpan"
                  )}
                </Button>
                <Button
                  variant="contained"
                  color="default"
                  onClick={handleClose}
                >
                  Batal
                </Button>
              </ButtonGroup>
            </div>
          </ValidatorForm>
        </div>
      </Dialog>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addUser: (params) => dispatch(addUser(params)),
    getListUser: () => dispatch(getListUser()),
  };
};

export default connect(null, mapDispatchToProps)(AddUser);
