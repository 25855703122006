import "./App.css";
import "./styles/styles.css";

import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import store from "./redux";
import Routes from "./routes/routes";

function App() {
	return (
		<Provider store={store}>
			<BrowserRouter>
				<Routes />
			</BrowserRouter>
		</Provider>
	);
}

export default App;
