import { API, setAuthToken } from '../../config/api';
import { GET_DETAIL_USER, LOGIN, LOGOUT } from '../../constants/constants';

export const login = (params) => {
	return {
		type: LOGIN,
		payload: async () => {
			const res = await API.post("login", params).catch((err) => {
				return Promise.reject(err);
			});
			if (res.data.code === 0) {
				localStorage.setItem("token", res.data.data.api_token);
				localStorage.setItem("id", res.data.data.id);
				localStorage.setItem("email", res.data.data.email);
				localStorage.setItem("role", res.data.data.role);
				return res.data.data;
			} else {
				return res.data;
			}
		},
	};
};

export const logout = () => {
	return {
		type: LOGOUT,
		payload: async () => {
			// const token = await localStorage.getItem("token");
			// setAuthToken(token);
			// const res = await API.post("user/logout");
			// console.log("result", res);
			localStorage.clear();
		},
	};
};

export const getDetailUser = (params) => {
	return {
		type: GET_DETAIL_USER,
		payload: async () => {
			const token = await localStorage.getItem("token");
			setAuthToken(token);
			const res = await API.get("user/profile", params).catch((err) => {
				return Promise.reject(err);
			});
			if (res.data.code === 0) {
				localStorage.setItem("id", res.data.data.id);
				localStorage.setItem("email", res.data.data.email);
				localStorage.setItem("role", res.data.data.role);
				return res.data.data;
			} else {
				return res.data.data;
			}
		},
	};
};
