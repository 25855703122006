import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";
import { connect } from "react-redux";

import { LogoWColor } from "../../assets";
import { CardApp, MainHeader } from "../../component";
import { getDetailUser, logout } from "../../redux/actions/auth";

class Home extends Component {
  constructor() {
    super();
    this.state = {
      token: "",
    };
  }
  componentDidMount() {
    const { getDetailUser, history } = this.props;
    getDetailUser().catch((err) => {
      localStorage.clear();
      history.push("/login");
    });
    this.getToken();
  }

  getToken = async () => {
    const token = await localStorage.getItem("token");
    this.setState({
      token: token,
    });
  };

  render() {
    const { token } = this.state;
    const { user, history } = this.props;
    console.log("iser", user?.role.includes("1"));
    return (
      <div className="container-welcome">
        <div className="ilustration-background">
          <Container>
            <MainHeader history={history} />
            <Row
              className={`container-app ${
                token ? null : "d-none"
              } justify-content-center`}
            >
              {(user?.role.includes("1") || user?.role.includes("3")) && (
                <CardApp
                  link={`https://finance.alan.co.id/check_token/${token}`}
                  app="Finance"
                  desc="Management of your money easily"
                  logo={LogoWColor}
                />
              )}
              {/* {(user?.role.includes("1") || user?.role.includes("4")) && (
                <CardApp
                  link="https://finance.alan.co.id/dashboard"
                  app="HR"
                  desc="Management of your money easily"
                  logo={LogoWColor}
                />
              )} */}
              {/* {(user?.role.includes("1") ||
                user?.role.includes("5") ||
                user?.role.includes("2")) && (
                <CardApp
                  link={`https://administrasi.alan.co.id/check_token/${token}`}
                  app="Administration"
                  desc="Management of your money easily"
                  logo={LogoWColor}
                />
              )} */}
              {(user?.role.includes("1") ||
                user?.role.includes("6") ||
                user?.role.includes("99")) && (
                <CardApp
                  link={`https://crm.alan.co.id/check_token/${token}`}
                  app="CRM"
                  desc="Customer Relationship Management"
                  logo={LogoWColor}
                />
              )}
              {(user?.role.includes("1") ||
                user?.role.includes("5") ||
                user?.role.includes("6") ||
                user?.role.includes("2")) && (
                <CardApp
                  link={`https://crp.alan.co.id/check_token/${token}`}
                  app="Correspondence"
                  desc="Management of your letter"
                  logo={LogoWColor}
                />
              )}
              {(user?.role.includes("1") ||
                user?.role.includes("7") ||
                user?.role.includes("8")) && (
                <CardApp
                  link={`https://hr.alan.co.id/check_token/${token}`}
                  app="HR"
                  desc="Human Resource Management"
                  logo={LogoWColor}
                />
              )}
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    getDetailUser: () => dispatch(getDetailUser()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
