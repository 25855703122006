import { Button, ButtonGroup, Card, Chip, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import { MainHeader } from '../../component';
import { getDetailUser, logout } from '../../redux/actions/auth';
import { delUser, getListUser } from '../../redux/actions/managementUser';
import AddUser from './AddUser';
import EditUser from './EditUser';

class ManagementUser extends Component {
	constructor() {
		super();
		this.state = {
			token: "",
			openAdd: false,
			openEdit: false,
			detailUser: {
				email: "",
				name: "",
				role: "",
				id: "",
			},
		};
	}
	componentDidMount() {
		const { getDetailUser, getListUser, history } = this.props;
		getListUser();
		getDetailUser()
			.then((res) => {
				console.log("result", res);
			})
			.catch((err) => {
				localStorage.clear();
				history.push("/login");
			});
		this.getToken();
	}

	getToken = async () => {
		const token = await localStorage.getItem("token");
		this.setState({
			token: token,
		});
	};

	handleModalAdd = () => {
		const { openAdd } = this.state;
		this.setState({
			openAdd: !openAdd,
		});
	};

	handleDel = (id) => {
		const { delUser, getListUser } = this.props;
		Swal.fire({
			title: "Peringatan",
			text: "Apakah kamu yakin ingin menghapus user ini?",
			showCancelButton: true,
			confirmButtonText: "Iya",
			cancelButtonText: "Tidak",
			icon: "warning",
		}).then((res) => {
			if (res.isConfirmed) {
				delUser(id)
					.then(() => {
						Swal.fire({
							title: "Berhasil",
							text: "User berhasil dihapus",
							timer: 2000,
							icon: "success",
						});
						getListUser();
					})
					.catch((err) => {
						Swal.fire({
							title: "Gagal",
							text: "User gagal dihapus",
							timer: 2000,
							icon: "success",
						});
					});
			}
		});
	};

	handleEdit = (data) => {
		this.setState({
			openEdit: true,
			detailUser: data,
		});
	};

	handleCloseEdit = () => {
		this.setState({
			openEdit: false,
			detailUser: {
				email: "",
				nama: "",
				role: "",
				id: "",
			},
		});
	};

	render() {
		const { dataUser } = this.props;
		const { openAdd, openEdit, detailUser } = this.state;
		return (
			<div className="container-welcome">
				<div className="ilustration-background">
					<Container>
						<MainHeader history={this.props.history} />
						<Row>
							<Col>
								<Card className="p-5">
									<div className="d-flex align-items-center justify-content-between">
										<h5>Management User</h5>
										<Button variant="contained" color="primary" size="small" className="bg-primary" onClick={this.handleModalAdd}>
											<Add />
										</Button>
									</div>
									<div className="w-100 overflow-auto mt-5">
										<Table className="mt-4">
											<TableHead>
												<TableRow>
													<TableCell>No</TableCell>
													<TableCell>Name</TableCell>
													<TableCell>Email</TableCell>
													<TableCell>Status</TableCell>
													<TableCell align="center">Aksi</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{dataUser?.length > 0 ? (
													dataUser.map((item, index) => (
														<TableRow hover key={index}>
															<TableCell>{index + 1}</TableCell>
															<TableCell>{item.name}</TableCell>
															<TableCell>{item.email}</TableCell>
															<TableCell>
																<Chip label={item.status ? "Active" : "Non-Active"} color={item.status ? "primary" : "secondary"} />
															</TableCell>
															<TableCell align="center">
																<ButtonGroup>
																	<Button
																		variant="contained"
																		color="primary"
																		size="small"
																		onClick={() => this.handleEdit(item)}
																		className="bg-primary"
																	>
																		Edit
																	</Button>
																	<Button
																		variant="contained"
																		color="secondary"
																		size="small"
																		onClick={() => this.handleDel(item.id)}
																	>
																		Hapus
																	</Button>
																</ButtonGroup>
															</TableCell>
														</TableRow>
													))
												) : (
													<TableRow hover>
														<TableCell colSpan={4} align="center">
															Data kosong
														</TableCell>
													</TableRow>
												)}
											</TableBody>
										</Table>
									</div>
								</Card>
							</Col>
						</Row>
					</Container>
					<AddUser open={openAdd} handleClose={this.handleModalAdd} />
					{openEdit && (
						<EditUser
							open={openEdit}
							handleClose={this.handleCloseEdit}
							id={detailUser.id}
							email={detailUser.email}
							nama={detailUser.name}
							role={detailUser.role}
							status={detailUser.status}
						/>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		dataUser: state.managementUser.user,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		logout: () => dispatch(logout()),
		getDetailUser: () => dispatch(getDetailUser()),
		getListUser: () => dispatch(getListUser()),
		delUser: (id) => dispatch(delUser(id)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ManagementUser);
