import { DEL_USER, GET_LIST_USER } from '../../constants/constants';

const initState = {
	user: [],
};

const managementUser = (state = initState, action) => {
	switch (action.type) {
		case GET_LIST_USER + "_PENDING":
			return {
				...state,
				user: [],
			};
		case GET_LIST_USER + "_FULFILLED":
			return {
				...state,
				user: action.payload,
			};
		case GET_LIST_USER + "_REJECTED":
			return {
				...state,
				user: [],
			};
		case DEL_USER + "_PENDING":
			return state;
		case DEL_USER + "_FULFILLED":
			let user = state.user.filter((item) => item.id !== action.payload);
			return {
				user: user,
			};
		case DEL_USER + "_REJECTED":
			return state;
		default:
			return state;
	}
};

export default managementUser;
