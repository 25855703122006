import { API, setAuthToken } from "../../config/api";

export const editHeader = (params) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("token");
    setAuthToken(token);
    const res = await API.post(`user/kop/edit`, params).catch((err) => {
      return Promise.reject(err);
    });
    return res.data;
  };
};
